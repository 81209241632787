<template>
  <div class="sticky top-btn z-index">
    <a
      class="focus:outline-none transform transition-all hover:scale-110 absolute right-6 -top-12"
      :href="url"
      target="_blank"
    >
      <img
        src="../../assets/images/wpp.png"
        alt="Fincas-Agrogo"
        width="50"
        height="50"
      />
    </a>
  </div>
</template>
<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";

export default {
  inject: ["provider"],
  data() {
    return {
      repository: this.provider.settingRepository,
      url: "",
    };
  },
  methods: {
    async getMainOption() {
      try {
        const me = this;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.save({
          ...dataAditional,
          name: "WPP_BUTTON",
        });
        me.url = data?.configurations[0]?.value;
      } catch (error) {
        // console.log(error);
      }
    },
    loadImg(path) {
      const url = `${API_ROUTES.image.get}${path}`;
      return url;
    },
  },
  mounted() {
    const me = this;
    me.getMainOption();
  },
};
</script>
<style lang="css">
/* BOTON DE AGREGAR O PUBLICAR*/
.z-index {
  z-index: 99 !important;
}
/* POSICION BOTON */
.top-btn {
  top: 610px;
}
@media (min-width: 768px) {
  .top-btn {
    top: 641px;
  }
}
@media (min-width: 1280px) {
  .top-btn {
    top: 736px;
  }
}
</style>
